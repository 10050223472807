body{
    margin: 0;

    .loginPage{
        // background: linear-gradient(to bottom, #b4e391 0%,#61c419 50%,#b4e391 100%);
    }
}

.p-divider-solid.p-divider-vertical:before {
    border-left-style: solid !important;
}

.p-button-label {
    pointer-events: none;
}